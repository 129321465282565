import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './utils/i18n';
import App from './components/App';
import Loader from './components/Loader';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>,
);
