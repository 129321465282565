import React from 'react';
import TribeLogo from '../TribeLogo';
import styles from './index.module.scss';

function LogoSection() {
  return (
    <div className="dark">
      <div className={`${styles.container} container`}>
        <TribeLogo />
      </div>
    </div>
  );
}

export default LogoSection;
