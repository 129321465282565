import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './index.module.scss';

function IntroductionSection() {
  const { t } = useTranslation();

  return (
    <main className="light">
      <div className={`${styles.container} container`}>
        <h2 className={styles.title}>
          <Trans components={{ span: <span /> }}>greetings</Trans>
        </h2>
        <p className={styles.info}>{t('greetings_info')}</p>
      </div>
    </main>
  );
}

export default IntroductionSection;
