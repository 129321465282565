/* eslint-disable react/no-array-index-key */
import styles from './style.module.scss';

function Loader() {
  return (
    <div className={styles.mainLoader}>
      <div className={styles.boxes}>
        {new Array(4).fill(null).map((_box, i) => (
          <div key={i} className={styles.box}>
            {new Array(4).fill(null).map((_div, index) => (
              <div key={index} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Loader;
