export function getNumberOfDays(start: string, end?: string) {
  const date1 = new Date(start);
  const date2 = end ? new Date(end) : new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();

  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
}

export function restylePhoneNumber(phone: string) {
  return phone.replace(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/, '(+$1) $2-$3-$4-$5');
}
