import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import telegram from '../../assets/telegram.png';
import whatsUp from '../../assets/whatsUp.png';
import phone from '../../assets/phone.svg';
import mail from '../../assets/mail.svg';
import { restylePhoneNumber } from '../../utils/helpers';

function ContactSection() {
  const { t } = useTranslation();
  const phoneCompany = process.env.REACT_APP_PHONE as string;
  const emailCompany = process.env.REACT_APP_EMAIL as string;
  const telegramCompany = process.env.REACT_APP_TELEGRAM as string;

  return (
    <footer className="dark">
      <div className="container">
        <p className={styles.info}>{t('contact_title')}</p>
        <div className={styles.contacts}>
          <div className={styles.oldConnection}>
            <div className={styles.phone}>
              <a href={`tel:+${phoneCompany}`}>
                <img src={phone} alt="phone" />
                <span>{restylePhoneNumber(phoneCompany)}</span>
              </a>
            </div>
            <div className={styles.mail}>
              <a href={`mailto:${emailCompany}`}>
                <img src={mail} alt="mail" />
                <span>{emailCompany}</span>
              </a>
            </div>
          </div>
          <div className={styles.newConnection}>
            <a href={`https://t.me/${telegramCompany}`}>
              <img src={telegram} alt="telegram" width={20} />
            </a>
            <a href={`https://wa.me/${phoneCompany}`}>
              <img src={whatsUp} alt="whatsUp" width={20} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default ContactSection;
