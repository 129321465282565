import React, { useEffect, useRef } from 'react';
import { dayOfEnd, dayOfStart } from '../../utils/constants';
import { getNumberOfDays } from '../../utils/helpers';
import styles from './index.module.scss';

function TribeLogo() {
  const parentRef = useRef<SVGSVGElement>(null);

  function startBlinking(elem: Element, color: string) {
    elem.setAttribute('style', `stroke:${color};stroke-width:4`);
    // eslint-disable-next-line no-param-reassign
    elem.innerHTML = `<animate attributeType="XML" attributeName="fill" values="${color};transparent;${color}" dur="2.8s" repeatCount="indefinite"/>`;
  }

  useEffect(() => {
    const numberOfAllDays = getNumberOfDays(dayOfStart, dayOfEnd);
    const numberOfDays = getNumberOfDays(dayOfStart);
    const parentDiv = parentRef.current as SVGSVGElement;
    const polygons = parentDiv.children;
    const polygonsPerDay = polygons.length / numberOfAllDays;
    const progress = Math.ceil(polygonsPerDay * numberOfDays);

    for (let i = 0; i < polygons.length; i += 1) {
      const polygon = polygons[i];
      const color = polygon.getAttribute('fill') as string;
      if (i <= progress) {
        if (i === progress) {
          startBlinking(polygon, color);
        }
        polygon.setAttribute('style', 'stroke:gray;stroke-width:0.5');
      } else {
        polygon.setAttribute('fill', 'transparent');
        polygon.setAttribute('style', `stroke: ${color}; stroke-width:1`);
      }
    }
  }, []);

  return (
    <svg
      version="1.1"
      className={styles.progressBar}
      id="Tribe"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 800 350"
      xmlSpace="preserve"
      ref={parentRef}
    >
      <path
        id="_x3C_Path_x3E_76"
        fill="#EF9635"
        d="M780.1,211.2c0-10-1.4-18.8-4.3-26.3c-1.1-3-2.5-5.8-3.9-8.4L745,211.8L780.1,211.2z"
      />
      <path
        id="_x3C_Path_x3E_75"
        fill="#F12836"
        d="M137.2,141.8l12.5-68.4l0,0l-65.3,75l16.9,24.1l36.1-30.2
	C137.3,142,137.2,141.9,137.2,141.8z"
      />
      <path
        id="_x3C_Path_x3E_74_1_"
        fill="#EF9635"
        d="M746.3,190.3l-17.3,0.5l5.7,21.1l10.3-0.1l26.9-35.2c-2-3.6-4.2-6.7-6.8-9.6
	l-28.3,11.4C741.1,180.6,744.3,184.5,746.3,190.3z"
      />
      <path
        id="_x3C_Path_x3E_73_1_"
        fill="#F12836"
        d="M165.7,178.1l-64.5-5.8l41,58.6l23.6-52.2l0-0.1l0,0
	C165.8,178.3,165.8,178.2,165.7,178.1z"
      />
      <path
        id="_x3C_Path_x3E_72_1_"
        fill="#EF9635"
        d="M727,176.1c3.7,0,6.8,1,9.8,2.3l28.4-11.3c-0.1-0.3-0.6-0.8-0.8-1
	c-4.8-5-10.4-8.8-16.8-11.3c-6.4-2.5-13.3-3.8-20.5-3.8c-1.8,0-3.5,0.1-5.2,0.2l3.8,25C726.1,176.2,726.5,176.1,727,176.1z"
      />
      <path
        id="_x3C_Path_x3E_71_1_"
        fill="#EF9635"
        d="M725.7,176.2l-3.8-25c-5.8,0.4-11.3,1.7-16.4,3.7c-6.1,2.4-11.6,5.7-16.1,9.8
	l27.6,13.6C719.4,177.3,722.7,176.4,725.7,176.2z"
      />
      <path
        id="_x3C_Path_x3E_70_1_"
        fill="#EF9635"
        d="M713.6,180.2c1.1-0.8,2.1-1.4,3.4-2l-27.6-13.6c-0.3,0.3-0.5,0.5-0.8,0.8
	c-0.7,0.7-1.4,1.4-2.1,2.2l20.1,21.4C708.3,185.4,710.6,182.4,713.6,180.2z"
      />
      <polygon
        id="_x3C_Path_x3E_69_1_"
        fill="#EF9635"
        points="708.9,191.2 728.3,211.9 734.7,211.9 729,190.8 "
      />
      <path
        id="_x3C_Path_x3E_68_1_"
        fill="#ED1C24"
        d="M142.2,230.9l-57.8-82.5l65.3-75l10.3-56c0-1.1-1.1-0.9-1.4-0.5L67.4,140.7
	c-0.1,0.2-0.1,0.5,0.1,0.7l57.4,81.8c0.1,0.1,0.1,0.1,0.2,0.2l35.5,21.3c0.4,0.3,0.9,0,0.9-0.4l0.1-1.6L142.2,230.9z"
      />
      <path
        id="_x3C_Path_x3E_67_1_"
        fill="#EF9635"
        d="M728.3,211.9l-19.4-20.7l-3.1,0.1c0.3-0.8,0.6-1.6,0.9-2.4l-20.1-21.4
	c-1.4,1.5-2.6,3.1-3.8,4.8l10.3,34.7l15.7,5.2L728.3,211.9z"
      />
      <path
        id="_x3C_Path_x3E_66_1_"
        fill="#EF9635"
        d="M677.8,181.6c-2.6,6.3-3.9,13.2-3.9,20.8c0,6.9,1,13.1,3.1,18.9l16.1-14.2l-10.3-34.8
	C680.9,175.1,679.2,178.2,677.8,181.6z"
      />
      <polygon
        id="_x3C_Path_x3E_65_1_"
        fill="#F12836"
        points="165.7,178.1 101.2,172.3 137.3,142.1 "
      />
      <path
        id="_x3C_Path_x3E_64_1_"
        fill="#EF9635"
        d="M677,221.3c0.3,1,0.7,1.9,1.1,2.8c2.3,5.3,4.5,9.3,8.8,14.1l6.2-31.2L677,221.3z"
      />
      <path
        id="_x3C_Path_x3E_63_1_"
        fill="#EF9635"
        d="M740.7,225.1l19.6,19.2c7.6-5.7,12.7-13.3,15.9-22.6h-32.5
	C742.8,222.9,741.6,224.2,740.7,225.1z"
      />
      <path
        id="_x3C_Path_x3E_62_1_"
        fill="#EF9635"
        d="M736.3,228c-0.3,0.1-0.6,0.3-0.8,0.4l-7.5,26.9c10.9-0.3,20.7-3.2,29.2-8.8
	c1.1-0.7,2.1-1.5,3.1-2.2L740.8,225C739.4,226.2,737.9,227.2,736.3,228z"
      />
      <path
        id="_x3C_Path_x3E_61_1_"
        fill="#EF9635"
        d="M726.2,230.3l-24.8,19.2c1.5,0.8,3,1.5,4.6,2.1c6.3,2.5,13.1,3.8,20.2,3.8
	c0.6,0,1.2,0,1.8,0l7.5-27C732.7,229.3,729.6,230.2,726.2,230.3z"
      />
      <path
        id="_x3C_Path_x3E_60_1_"
        fill="#EF9635"
        d="M726.2,230.3c-5,0-9.3-1.6-13-4.7c-3.7-3.1-6.3-7.6-7.9-13.3l3.7-0.1l-15.5-5.1
	l-0.1,0l-0.3-0.1l-6.2,31.2l0.1,0.1l0.2,0.2c0.8,0.9,1.6,1.5,2.4,2.3c3.5,3.5,7.5,6.4,12,8.6L726.2,230.3L726.2,230.3z"
      />
      <polygon
        id="_x3C_Path_x3E_59_1_"
        fill="#F12836"
        points="161.6,242.7 165.8,178.6 165.8,178.6 142.2,230.9 "
      />
      <polygon
        id="_x3C_Path_x3E_58_1_"
        fill="#EF9635"
        points="576.3,115.4 576.3,94 564.3,94 568.1,118.8 "
      />
      <polygon
        id="_x3C_Path_x3E_57_1_"
        fill="#EF9635"
        points="564.3,94 546.8,94 546.8,108.5 568.1,118.8 "
      />
      <polygon
        id="_x3C_Path_x3E_56_1_"
        fill="#EF9635"
        points="576.3,115.4 568.1,118.8 576.3,148.3 "
      />
      <polygon
        id="_x3C_Path_x3E_55_1_"
        fill="#EF9635"
        points="576.3,148.3 568.1,118.8 546.8,108.5 546.8,123.5 576.3,155 "
      />
      <polygon
        id="_x3C_Path_x3E_54_1_"
        fill="#EF9635"
        points="576.3,155 546.8,123.5 546.8,147.2 576.3,157 "
      />
      <polygon
        id="_x3C_Path_x3E_53_1_"
        fill="#EF9635"
        points="576.3,157 546.8,147.2 546.8,182.7 576.3,159.9 "
      />
      <polygon
        id="_x3C_Path_x3E_52_1_"
        fill="#F1CE29"
        points="181.4,150.8 190.4,115.6 176.4,93.5 163,135.9 "
      />
      <path
        id="_x3C_Path_x3E_51_1_"
        fill="#EF9635"
        d="M608.6,151.1c-8.6,0-15.5,1.4-20.9,4.2c-1.1,0.5-1.9,1.1-2.9,1.7l33.9-4.9
	C615.6,151.4,612.1,151.1,608.6,151.1z"
      />
      <path
        id="_x3C_Path_x3E_50_1_"
        fill="#EF9635"
        d="M618.7,152.1l-33.9,4.9c-1.4,0.9-2.7,1.8-3.9,2.8l16.9,16.7c1.2-0.2,2.3-0.3,3.5-0.3
	c3.1,0,6.2,0.5,9.2,1.6c2.9,1,5.2,2.7,7.7,4.6l4.7-29.3C621.6,152.7,620.1,152.3,618.7,152.1z"
      />
      <path
        id="_x3C_Path_x3E_49_1_"
        fill="#EF9635"
        d="M643,165.4c-4.2-4.5-9.2-8-15.1-10.5c-1.6-0.7-3.3-1.3-5-1.8l-4.7,29.3
	c0.1,0.1,0.1,0.1,0.3,0.2c2.3,2.1,4,4.8,5.5,8.2l26.9-13.8C648.7,172.7,646.1,168.7,643,165.4z"
      />
      <path
        id="_x3C_Path_x3E_48_1_"
        fill="#EF9635"
        d="M576.3,164.8v-4.9l-29.5,22.8v31l14.4,7.9l16.3-58.4
	C577.1,163.7,576.7,164.3,576.3,164.8z"
      />
      <path
        id="_x3C_Path_x3E_47_1_"
        fill="#EF9635"
        d="M650.9,177L624,190.7v0.1c0.6,1.4,1.1,3,1.4,4.6l30.7,2.7c-0.4-6-1.5-11.5-3.4-16.6
	C652.2,179.9,651.6,178.4,650.9,177z"
      />
      <path
        id="_x3C_Path_x3E_46_1_"
        fill="#F1CE29"
        d="M222,165.8l-31.6-50.2l0,0l-9,35.2l17.4,14l-3.6,12.6l25.6-9.3l0,0l1.2-1.7
	C222.1,166.3,222.1,166,222,165.8z"
      />
      <path
        id="_x3C_Path_x3E_45_1_"
        fill="#EF9635"
        d="M577.5,163.2l-16.3,58.4l16.6-8.1c-1-3-1.5-6.3-1.5-9.9v-0.9c0-4.1,0.7-7.8,2.1-11.1
	c1.4-3.3,3.3-6.1,5.7-8.3c2.4-2.3,5-4,8-5.2c1.9-0.8,3.8-1.3,5.7-1.6l-16.9-16.7C579.6,160.8,578.5,162,577.5,163.2z"
      />
      <path
        id="_x3C_Path_x3E_44_1_"
        fill="#EF9635"
        d="M656.2,198.1l-30.8-2.7c0.5,2.2,0.8,4.6,0.8,7.2c0,3.6-0.7,7-1.4,10.1l14.6,30.9
	c1.2-1,2.3-2.3,3.3-3.5c4.4-4.8,7.5-10.4,10-16.9c2.4-6.5,3.6-13.4,3.6-20.9C656.3,201,656.3,199.5,656.2,198.1z"
      />
      <path
        id="_x3C_Path_x3E_43_1_"
        fill="#F1CE29"
        d="M162.6,71.4c-0.3-0.6-1.3-1.1-1.8,0.1l-11.2,63.8c0,0.2,0,0.4,0.2,0.6l35.6,28.8
	c0.2,0.1,0.2,0.3,0.2,0.6l-11.9,70c-0.1,0.6,0.7,0.9,1.1,0.4l6.4-9.3l17.6-61.6L163,135.9l13.4-42.4L162.6,71.4z"
      />
      <path
        id="_x3C_Path_x3E_42_1_"
        fill="#EF9635"
        d="M624,214.8c-1.4,3.5-3.3,6.4-5.6,8.8c-1.3,1.3-2.7,2.4-4.2,3.4l-1.7,28.4
	c5.3-0.5,10.2-1.8,14.8-3.8c4.5-2.1,8.5-4.7,12.1-8l-14.6-30.9C624.6,213.3,624.3,214.1,624,214.8z"
      />
      <path
        id="_x3C_Path_x3E_41_1_"
        fill="#EF9635"
        d="M610.5,228.8c-3,1.1-6.1,1.7-9.2,1.7c-0.2,0-0.4,0-0.5,0l-9.6,22.3
	c4.6,1.8,10.1,2.5,16.6,2.7c1.6,0,2.9,0,4.7-0.1l1.8-28.5C613.1,227.6,611.9,228.3,610.5,228.8z"
      />
      <path
        id="_x3C_Path_x3E_40_1_"
        fill="#EF9635"
        d="M576.3,241.1L576.3,241.1L576.3,241.1c0.4,0.6,0.7,0.9,1.1,1.4l0,0
	c2.7,3.6,6.3,6.5,10.7,8.9c0.9,0.5,2,1,3.1,1.4l9.6-22.3c-3-0.1-5.9-0.6-8.8-1.7c-3-1.1-5.7-2.8-8-5c-2.4-2.2-4.2-5-5.7-8.5
	c-0.2-0.6-0.5-1.2-0.7-1.8l-11,5.4l-5.5,2.7L576.3,241.1z"
      />
      <polygon
        id="_x3C_Path_x3E_39_1_"
        fill="#EF9635"
        points="561.2,221.6 561.2,221.6 561.2,221.6 561.2,221.6 546.8,213.7 546.8,221.1
	576.3,246.6 576.3,241.1 576.3,241.1 "
      />
      <polygon
        id="_x3C_Path_x3E_38_1_"
        fill="#EF9635"
        points="546.8,253 576.3,253 576.3,246.6 546.8,221.1 "
      />
      <polygon
        id="_x3C_Path_x3E_37_1_"
        fill="#F1CE29"
        points="195.2,177.4 181.2,226.4 181.2,226.4 220.8,168.1 "
      />
      <path
        id="_x3C_Path_x3E_36_1_"
        fill="#EF9635"
        d="M519.1,127.8c0.6-2,0.9-3.9,0.9-5.8c0-1.7-0.3-3.6-0.9-5.6c-0.6-2-1.5-3.9-2.9-5.6
	s-3.1-3.1-5.3-4.2s-5-1.6-8.2-1.6c-3.1,0-5.8,0.5-8,1.6s-4,2.5-5.2,4.2c-1.3,1.7-2.2,3.6-2.8,5.6c-0.6,2-0.9,3.9-0.9,5.6
	c0,0.4,0,0.7,0,1.1l32.9,5.9C518.9,128.7,519,128.3,519.1,127.8z"
      />
      <path
        id="_x3C_Path_x3E_35_1_"
        fill="#EF9635"
        d="M489.4,133.4c1.3,1.7,3,3.1,5.2,4.2c2.2,1.1,4.9,1.6,8,1.6c3.3,0,6-0.5,8.2-1.6
	c2.2-1.1,4-2.5,5.3-4.2c1.1-1.3,2-3,2.6-4.5l-33-5.9c0.1,1.5,0.3,3.3,0.7,4.9C487.2,129.8,488.1,131.7,489.4,133.4z"
      />
      <path
        id="_x3C_Path_x3E_34_1_"
        fill="#B71C9D"
        d="M231.7,205.1l2,29.8l31.9-25.9l0.1-0.1l-33.6-3.9C231.9,205,231.8,205,231.7,205.1"
      />
      <polygon
        id="_x3C_Path_x3E_33_1_"
        fill="#EF9635"
        points="517.6,152.9 488.1,152.9 488.1,156.4 517.6,182.4 "
      />
      <path
        id="_x3C_Path_x3E_32_1_"
        fill="#B71C9D"
        d="M199.1,263l16.4-13.3l3.3-2.7l-2.1-0.5l-22.1-5.3l0,0l0,0l0,0l-14.3,13.9
	c-0.8,0.8-0.1,1.5,0.3,1.7C183.9,258,192.4,260.9,199.1,263L199.1,263L199.1,263z"
      />
      <polygon
        id="_x3C_Path_x3E_31_1_"
        fill="#EF9635"
        points="517.6,192.7 517.6,182.4 488.1,156.4 488.1,188.4 "
      />
      <polygon
        id="_x3C_Path_x3E_30_1_"
        fill="#EF9635"
        points="488.1,217.2 517.6,202 517.6,192.7 488.1,188.4 "
      />
      <polygon
        id="_x3C_Path_x3E_29_1_"
        fill="#EF9635"
        points="517.6,238.3 517.6,202 488.1,217.2 488.1,222.6 "
      />
      <polygon
        id="_x3C_Path_x3E_28_1_"
        fill="#B71C9D"
        points="194.6,241.2 216.8,246.5 218.8,247 231.4,236.8 233.7,234.9 231.7,205.1 "
      />
      <polygon
        id="_x3C_Path_x3E_27_1_"
        fill="#EF9635"
        points="517.6,238.3 488.1,222.6 488.1,226.3 509.4,253 517.6,253 "
      />
      <polygon id="_x3C_Path_x3E_26_1_" fill="#EF9635" points="488.1,253 509.4,253 488.1,226.3 " />
      <path
        id="_x3C_Path_x3E_25_1_"
        fill="#A11C95"
        d="M267.2,221c0.4-0.4-0.8-10.4-1.4-11.9l-0.1-0.2l-31.9,25.9l0,0l-2.3,1.9l-15.9,12.9
	L199.1,263c3.4,1.1,6.3,1.9,7.8,2.3c0.1,0,0.2,0,0.3,0s0.2-0.1,0.3-0.2L267.2,221z"
      />
      <path
        id="_x3C_Path_x3E_24_1_"
        fill="#EF9635"
        d="M467.6,151.4c-3.3-0.4-6.4-0.5-9.5-0.3l9.4,6.6L467.6,151.4z"
      />
      <path
        id="_x3C_Path_x3E_23_1_"
        fill="#EF9635"
        d="M454.4,151.5l12.9,15.5l0.2-9.3l-9.4-6.6C456.8,151.1,455.6,151.3,454.4,151.5z"
      />
      <path
        id="_x3C_Path_x3E_22"
        fill="#B71C9D"
        d="M39.9,190.5c-0.1,0.2-0.1,0.4,0,0.6l17.5,37.7c0.1,0.1,0.2,0.2,0.3,0.3
	c11.7,9,39.2,23.3,69.1,36.5l78.8,9.9c-81-20.1-133.7-73.3-155.6-100.5l0,0L39.9,190.5z"
      />
      <path
        id="_x3C_Path_x3E_21"
        fill="#EF9635"
        d="M448.6,152.7c-1,0.3-1.9,0.7-2.9,1.1l-6.5,26.9c4-3.2,9-4.8,14.9-4.8
	c3.9,0,8.2,0.6,13,1.7l0.2-10.7l-12.9-15.4C452.4,151.9,450.5,152.2,448.6,152.7z"
      />
      <polygon id="_x3C_Path_x3E_20" fill="#EF9635" points="402.1,152.6 402.1,160.8 424.4,152.6 " />
      <polygon
        id="_x3C_Path_x3E_19_1_"
        fill="#EF9635"
        points="431.6,152.6 424.4,152.6 402.1,160.8 402.1,181.5 431.6,157.4 "
      />
      <path
        id="_x3C_Path_x3E_18_1_"
        fill="#B71C9D"
        d="M246.8,282.4c-15.1-1.2-29.1-3.9-42.6-7.2l9.6,22.2c10.4-1.8,24.4-4.8,29.2-6.4
	C243.6,290.9,247.5,282.5,246.8,282.4z"
      />
      <path
        id="_x3C_Path_x3E_17"
        fill="#EF9635"
        d="M432.6,192.4c1-4.7,2.8-8.2,5.3-10.6c0.4-0.4,0.8-0.7,1.3-1.1l6.5-26.9
	c-4.8,2-9.5,5.2-14.1,9.7v-6.1l-29.5,24.1v7.6L432.6,192.4z"
      />
      <path
        id="_x3C_Path_x3E_16"
        fill="#A11C95"
        d="M45.5,169.6c-3.5-4.6-5.8-8-7-9.8c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.3-0.2
	s-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.5,0.3l-20,31.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.1v0.1l0,0v0.1l17.4,37.6c0,0.1,0.1,0.2,0.2,0.2l0.1,0.1
	c9.8,7.6,30.6,18.8,54.6,30c0.7,0.3,1.3,0.6,2,0.9c41.9,19.4,92.5,38.6,110.9,38.5c0.2,0,0.3,0,0.5,0h0.1c2.1-0.1,6.5-0.4,11-1.1
	c-20.5-4.5-55.6-17.8-87.5-32l0,0l0,0C96.7,252.2,69.3,238,57.6,229c-0.1-0.1-0.2-0.2-0.3-0.3L39.9,191c-0.1-0.2-0.1-0.4,0-0.6
	l10-15.4L45.5,169.6z"
      />
      <path
        id="_x3C_Path_x3E_15"
        fill="#EF9635"
        d="M402.1,197.4l29.5,32.9v-27.4c0-4,0.3-7.5,1-10.5l-30.5-3.3V197.4z"
      />
      <polygon
        id="_x3C_Path_x3E_14"
        fill="#EF9635"
        points="402.1,253 431.6,253 431.6,230.3 402.1,197.4 "
      />
      <path
        id="_x3C_Path_x3E_13"
        fill="#B71C9D"
        d="M127,265.7c31.9,14.2,66.4,27.2,86.9,31.7l0,0l-9.7-22.2L127,265.7z"
      />
      <polygon id="_x3C_Path_x3E_12" fill="#EF9635" points="378.4,176.6 378.4,162.6 368.7,176.6 " />
      <polygon
        id="_x3C_Path_x3E_11"
        fill="#EF9635"
        points="368.7,176.6 378.4,162.6 378.4,152.6 362.1,152.6 359.8,176.6 "
      />
      <polygon
        id="_x3C_Path_x3E_10"
        fill="#EF9635"
        points="351.7,176.6 359.8,176.6 362.1,152.6 351.6,152.6 351.6,116.9 326.6,124.9
	351.7,177.6 "
      />
      <path
        id="_x3C_Path_x3E_9_2_"
        fill="#F12836"
        d="M142.4,292.5l-22.4-8.4c-0.1,0-0.1,0-0.2,0L94.4,300
	C96.4,299.3,125.4,296.8,142.4,292.5"
      />
      <polygon
        id="_x3C_Path_x3E_8"
        fill="#EF9635"
        points="322.1,152.6 317.2,176.6 305,176.6 305,152.6 "
      />
      <polygon
        id="_x3C_Path_x3E_7"
        fill="#EF9635"
        points="322.1,200.3 351.7,193.2 351.7,177.6 326.6,124.9 322.1,126.3 322.1,152.6
	320.2,161.7 317.2,176.6 322.1,176.6 "
      />
      <path
        id="_x3C_Path_x3E_6"
        fill="#EF9635"
        d="M351.6,193.2l-29.5,7.1v9.2c0,4.8,0.5,9.2,1.5,13.2l28.1-25.2v-4.3H351.6z"
      />
      <path
        id="_x3C_Path_x3E_5"
        fill="#F12836"
        d="M61.3,282.4c0,0,0.2,0.1,0.6,0.3l25.2,13.5l7.3,3.8l0,0l25.4-15.9c-0.1,0-0.1,0-0.2,0
	L61.3,282.4z"
      />
      <path
        id="_x3C_Path_x3E_4"
        fill="#EF9635"
        d="M330.6,237.2l47.8-7.5v-1.3c-6.6-0.3-11.6-0.8-15.2-1.6s-6.2-2.2-7.9-4.3
	c-1.7-2.1-2.7-5-3.1-8.8s-0.5-8.8-0.5-15.1v-1.1l-28.1,25.2c0.4,1.6,0.8,3.1,1.3,4.5C326.2,231,328.1,234.3,330.6,237.2z"
      />
      <path
        id="_x3C_Path_x3E_3"
        fill="#EF9635"
        d="M378.4,229.7l-47.8,7.5c1.1,1.3,2.3,2.5,3.6,3.7c4.3,3.8,10.1,6.7,17.3,8.8
	c3.8,1.1,8.1,1.9,12.9,2.5l13.9-14.1v-8.4H378.4z"
      />
      <path
        id="_x3C_Path_x3E_2"
        fill="#EF9635"
        d="M364.5,252.1c1.2,0.1,2.4,0.3,3.6,0.4c3.2,0.3,6.7,0.4,10.3,0.5v-7.7V238L364.5,252.1z"
      />
      <path
        id="_x3C_Path_x3E_1"
        fill="#ED1C24"
        d="M149.2,295l-6.8-2.5c-17,4.4-45.9,6.7-47.9,7.4l0,0l0,0c-0.9-0.3-28.2-15-32.5-17.3
	l-0.6-0.3l-12.2,8.4c-0.3,0.1-0.5,0.4-0.5,0.7c0,0.1,0.1,0.2,0.1,0.3l0,0l0,0c0,0,0.1,0.1,0.2,0.1l41.7,20.3c0.1,0,0.1,0,0.2,0
	c0,0,0,0,0.1,0s0.2,0,0.2,0c1.7-0.7,24.2-6.4,41.2-10.8c0.2-0.1,0.5-0.1,0.7-0.2c0.4-0.1,0.8-0.2,1.2-0.3c6.4-1.7,11.9-3.1,14.8-3.9
	c0.1,0,0.2-0.1,0.2-0.1C149.8,296.5,149.9,295.2,149.2,295z"
      />
    </svg>
  );
}

export default TribeLogo;
