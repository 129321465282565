import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

function UnderConstructionSection() {
  const { t } = useTranslation();

  return (
    <div className={`${styles.container}`}>
      <div className={styles.upperElement} />
      <div className={styles.lowerElement}>
        {new Array(9).fill(null).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i}>{t('under_construction')}</span>
        ))}
      </div>
    </div>
  );
}

export default UnderConstructionSection;
