import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ukraineLanguages } from '../../utils/constants';
import LogoSection from '../LogoSection';
import UnderConstructionSection from '../UnderConstructionSection';
import IntroductionSection from '../IntroductionSection';
import ContactSection from '../ContactSection';

interface NavigatorLanguage {
  userLanguage?: string;
}

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const userLanguage =
      (window.navigator as NavigatorLanguage).userLanguage || window.navigator.language;
    const currentLanguage = ukraineLanguages.includes(userLanguage) ? 'uk' : 'en';
    i18n.changeLanguage(currentLanguage);
  }, [i18n]);

  return (
    <>
      <LogoSection />
      <UnderConstructionSection />
      <IntroductionSection />
      <ContactSection />
    </>
  );
}

export default App;
